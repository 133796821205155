<div class="toolbar-user-container">
  <button mat-button (click)="isOpen = !isOpen" class="toolbar-user-btn" [class.open]="isOpen"
          [ngStyle.xs]="{'min-width': '70px'}">
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" [src]="currentUser?.photoURL || 'assets/images/avatars/noavatar.png'" alt="">
      <span class="name" fxHide fxShow.gt-xs>{{currentUser?.currentUserName || Hari}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
    </span>
  </button>


  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content row">
      <div class="col">
        <a class="item" [routerLink]='["/auth/informacion"]' (click)="isOpen = !isOpen" *ngIf="isCiudadano">
          <button mat-icon-button>
            <mat-icon>person_outline</mat-icon>
          </button>
          <p>Mi Cuenta</p>
        </a>
        <a class="item" (click)="cerrarSesion()" (click)="isOpen = !isOpen">
          <button mat-icon-button>
            <mat-icon>exit_to_app</mat-icon>
          </button>
          <p>Cerrar sesión</p>
        </a>
      </div>
    </div>
  </div>
</div>
