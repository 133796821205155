<ng-scrollbar style="height: calc(100% - 33px);">
  <div fxLayout="column" class="container-menu">
    <div *ngIf="!iconOnly" fxLayoutAlign="space-around center" [style.margin]="'10px 0'">
      <img width="100" src="./assets/user-image.png" alt="">
    </div>
    <div *ngIf="iconOnly" fxLayoutAlign="space-around center">
      <img width="50" src="./assets/user-image.png" alt="">
    </div>
    <cdk-sidemenu-item *ngFor="let menu of menus" [menu]="menu" [allmenu]="menus" [iconOnly]="iconOnly"
                       (click)="addViewContent(menu.id)"></cdk-sidemenu-item>
    <div class="col-md-12" *ngIf="!isLoged">
      <div class="row justify-content-center  mt-5">
        <button class="btn-gen-succes col-md-8" mat-button [routerLink]='["/login"]'>
          <mat-icon *ngIf="iconOnly" matListIcon iconsmall title="Ingresar">login</mat-icon>
          <span *ngIf="!iconOnly">Ingresar</span>
        </button>
      </div>
      <div class="row justify-content-center mt-2">
        <button class="btn-gen-cancel col-md-8" mat-button [routerLink]='["/registro"]'>
          <mat-icon *ngIf="iconOnly" matListIcon iconsmall title="Registro">person_add_alt</mat-icon>
          <span *ngIf="!iconOnly">Registro</span>
        </button>
      </div>
    </div>
  </div>

</ng-scrollbar>
