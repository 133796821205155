<mat-toolbar class="mat-elevation-z4 bg-white">
  <button mat-icon-button (click)="sidenav.toggle();drawer.toggle();" *ngIf="matDrawerShow && isLoggedIn">
    <em class="material-icons app-toolbar-menu">menu </em>
  </button>
  <button mat-icon-button (click)="sidenav.toggle();" *ngIf="!matDrawerShow">
    <em class="material-icons app-toolbar-menu">menu </em>
  </button>

  <div class="input-group ocultar-buscar-tramite" *ngIf="isLoggedIn">
    <div class="input-group-text bg-transparent border-right-0 bg-gray buscar-tramite">
      <em class="fa fa-search"
          style="color: #76b82A"></em></div>
    <input class="form-control py-1 border-left-0 border bg-gray buscar-tramite" type="text"
           placeholder="¿Qué trámite necesitas?">
  </div>

  <span class="spacer"></span>

  <app-cdk-fullscreen [notifications]="toolbarHelpers?.messages" *ngIf="isLoggedIn"></app-cdk-fullscreen>

  <!--<app-cdk-toolbar-notification [notifications]="toolbarHelpers?.notifications" *ngIf="isLoggedIn"></app-cdk-toolbar-notification>-->

  <app-cdk-user-menu [currentUser]="toolbarHelpers?.currentUser" *ngIf="isLoggedIn"></app-cdk-user-menu>


</mat-toolbar>
