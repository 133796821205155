export const ToolbarHelpers = {
  notifications: [
    {
      id: 'id',
      title: 'Mail 5',
      lastTime: '23 Minutes ago',
      state: 'state'
    },
    {
      id: 'id',
      title: 'Mail 5',
      lastTime: '23 Minutes ago',
      state: 'state'
    },
    {
      id: 'id',
      title: 'Mail 5',
      lastTime: '23 Minutes ago',
      state: 'state'
    },
  ],

  currentUser: {
    photoURL: 'assets/images/avatars/hari.jpg',
    currentUserName: 'Diego Villa',
    currentEmail: 'diego.villa@Mentor360.dev'
  },

  messages: [
    {
      id: 'id',
      title: 'Mail 5',
      lastTime: '23 Minutes ago',
      state: 'state'
    },
    {
      id: 'id',
      title: 'Mail 5',
      lastTime: '23 Minutes ago',
      state: 'state'
    },
  ],
};
