import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidemenuComponent} from './sidemenu/sidemenu.component';
import {SidemenuItemComponent} from './sidemenu-item/sidemenu-item.component';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {RouterModule} from '@angular/router';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ToolbarNotificationComponent} from './toolbar-notification/toolbar-notification.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {FullscreenComponent} from './fullscreen/fullscreen.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {UserMenuComponent} from './user-menu/user-menu.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {usuarioService} from './coreservices/usuario.service';
import {cargarDocumentoService} from '../AdministracionDocumentos/services/cargarDocumento.service';
import {CategoriaModuloService} from './coreservices/CategoriaModulo.service';
import {ConfirmModalComponent} from './Modals/confirm-modal/confirm-modal.component';
import {ToastNoAnimationModule, ToastrModule, ToastrService} from 'ngx-toastr';
import {SpinnerService} from './coreservices/SpinnerService.service';
import {ConfirmModalDescriptionComponent} from './Modals/confirm-modal-description/confirm-modal-description.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterPipe} from './pipes/filter.pipe';
import {SuccessModalComponent} from './Modals/success-modal/success-modal.component';

@NgModule({

  declarations: [
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarNotificationComponent,
    ToolbarComponent,
    SearchBarComponent,
    FullscreenComponent,
    SidebarComponent,
    UserMenuComponent,
    ConfirmModalComponent,
    ConfirmModalDescriptionComponent,
    FilterPipe,
    SuccessModalComponent

  ],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    RouterModule,
    // PerfectScrollbarModule,
    NgScrollbarModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatTabsModule,
    MatSliderModule,
    MatProgressBarModule,
    ToastNoAnimationModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right'
      /*preventDuplicates: true,*/
    }),
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    SidemenuComponent,
    SidemenuItemComponent,
    ToolbarNotificationComponent,
    ToolbarComponent,
    SearchBarComponent,
    FullscreenComponent,
    SidebarComponent,
    UserMenuComponent,
    FilterPipe

  ],
  providers: [
    usuarioService,
    SpinnerService,
    cargarDocumentoService,
    CategoriaModuloService,
    ToastrService,
  ]
})
export class CoreModule {
}
