import {Component, OnInit, Input} from '@angular/core';
import {cargarDocumentoService} from '../../AdministracionDocumentos/services/cargarDocumento.service';
import {CategoriaModuloService} from '../coreservices/CategoriaModulo.service';
import {usuarioService} from '../coreservices/usuario.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ROLES} from 'environments/environment';

@Component({
  selector: 'cdk-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  @Input() iconOnly = false;
  public menus = [];
  isLoged = false;
  AllSubMenus = [];

  constructor(
    private _usuarioService: usuarioService,
    private _cargarDocumentoService: cargarDocumentoService,
    private _CategoriaModulo: CategoriaModuloService,
    private _MatIconRegistry: MatIconRegistry,
    private _DomSanitizer: DomSanitizer
  ) {
    this._MatIconRegistry.addSvgIcon(
      'reportes',
      this._DomSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/resources/icon/reportesIcon.svg'
      )
    );
  }

  containsArray(val: string, array: string[]) {
    let existe = false;
    const aux = val.split(',');
    for (let j = 0; j < aux.length; j++) {
      const result = array.find((x) => aux[j] === x);
      if (result) {
        existe = true;
        break;
      }
    }

    return existe;
  }

  ngOnInit() {
    this.isLoged = !!JSON.parse(localStorage.getItem('sessionLocal'))?.codigoUsuario;
    const rolUser = JSON.parse(
      localStorage.getItem('sessionLocal')
    )?.rolUsuario;

    const permission = rolUser ? [rolUser.toUpperCase()] : [];
    const applicationType = ['WEB', 'APP_WEB'];
    if (this.isLoged) {
      this._usuarioService.getMenu().subscribe((result) => {
        this.menus = result.datos.filter((item) => {
          return (
            applicationType.includes(item.tipoAplicacion) &&
            item.habilitado &&
            item.permiso.includes(permission[0])
          );
        });
        this.menus = this.menus.map((item) => {
          item.isSub = false;
          return item;
        });

        this.menus = this.menus.sort((a, b) =>
          a.ordenPantalla < b.ordenPantalla ? -1 : 1
        );
        this._usuarioService.getSubMenu().subscribe((subresult) => {
          this.AllSubMenus = subresult.datos.filter(
            (item) =>
              applicationType.includes(item.tipoAplicacion) &&
              item.habilitado &&
              this.containsArray(item.permiso, permission)
          );
          this.AllSubMenus = this.AllSubMenus.map((item) => {
            item.isSub = true;
            return item;
          });
          this.AllSubMenus = this.AllSubMenus.sort((a, b) =>
            a.ordenPantalla < b.ordenPantalla ? -1 : 1
          );

          this.menus.forEach((obj) => {
            if (
              this.AllSubMenus.filter((x) => x.referenciaContenido === obj.id)
                .length > 0
            ) {
              obj['sub'] = this.AllSubMenus.filter(
                (x) => x.referenciaContenido === obj.id
              );
              obj['open'] = false;
              obj['link'] = false;
              obj['click'] = false;
            } else {
              obj['open'] = false;
              obj['click'] = false;
            }
          });
          this.loadMenuChild(this.menus);
        });
      });
    }

    // this.setMenu();
    this._CategoriaModulo.ObtenerModuloMenu().subscribe((result) => {
      localStorage.setItem('CategoriaMenu', JSON.stringify(result));
    });
  }

  loadMenuChild(subresult) {
    subresult.forEach((obj) => {
      if (obj.sub?.length > 0) {
        this.loadMenuChild(obj.sub);
      } else {
        if (
          this.AllSubMenus.filter((x) => x.referenciaContenido === obj.id)
            .length > 0
        ) {
          obj['sub'] = this.AllSubMenus.filter(
            (x) => x.referenciaContenido === obj.id
          );
          obj['open'] = false;
          obj['link'] = false;
          obj['click'] = false;

          this.loadMenuChild([obj]);
        } else {
          obj['open'] = false;
          obj['click'] = false;
        }
      }
    });
  }

  addViewContent(idContent: string) {
    const userRole = JSON.parse(localStorage.getItem('sessionLocal'))?.rolUsuario;
    const userCode = JSON.parse(localStorage.getItem('sessionLocal'))?.idPersona;
    if (userRole === ROLES.CIUDADANOMICAV && userCode) {
      this._usuarioService
        .addViewContent(idContent, userCode)
        .subscribe((result) => {
          console.log(result);
        });
    }
  }
}
