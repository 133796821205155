import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {RequestResponse} from '../coremodels/requestResponse';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class usuarioService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  getMenu(): Observable<RequestResponse> {
    return this.http.get<RequestResponse>(`${this.baseUrl}/Contenido/WEB`);
  }

  getSubMenu(): Observable<RequestResponse> {
    return this.http.get<RequestResponse>(`${this.baseUrl}/SubContenido/WEB`);
  }

  addViewContent(idContent: string, idPerson: string) {
    return this.http.post(`${this.baseUrl}/Contenido/AgregarVisitaContenidos/${idContent}/${idPerson}`, {}, httpOptions);
  }

}
