<button
  class="button-close no-hover-effect"
  aria-label="Example icon-button with a heart icon"
  (click)="cerrarDialogo()"
>
  <mat-icon>close</mat-icon>
</button>
<div class="container">
  <h1 mat-dialog-title>Confirmación</h1>
  <div mat-dialog-content class="body">
    <strong>{{data.mensaje}}</strong>
  </div>
  <section class="container-buttons" mat-dialog-actions>
    <button class="button button-cancel col-md-4 col-sm-4 col-xs-10 mt-3" mat-button
            (click)="cerrarDialogo()">{{data.cancel}}</button>
    <button class="button button-confirm col-md-4 col-sm-4 col-xs-10 mt-3" mat-button
            (click)="confirmado()">{{data.accepted}}</button>
  </section>
</div>


