<mat-nav-list [style.maxHeight]="(menu.open)?'1200px':'48px'" [ngClass]="{'secondaryMenu': secondaryMenu,
                          'primary': !secondaryMenu}">
  <mat-list-item *ngIf='menu.link==false' (click)='menu.open = !menu.open' title="{{menu.nombreContenido}}">
    <mat-icon *ngIf="menu.iconoPersonalizado" matListIcon iconsmall svgIcon="{{menu.icono}}"></mat-icon>
    <mat-icon *ngIf="!menu.iconoPersonalizado" matListIcon iconsmall>{{menu.icono}} </mat-icon>
    <h3 matLine *ngIf='!iconOnly' title="{{menu.nombreContenido}}">{{ menu.nombreContenido }} </h3>
    <mat-chip-list *ngIf='menu?.chip && !iconOnly'>
      <mat-chip>{{menu?.chip?.value}} </mat-chip>
    </mat-chip-list>
    <mat-icon *ngIf='chechForChildMenu()' class='sidenav-dropdown-indicator rotate '
              [ngClass]="{'indicateOpen':menu.open}">
      expand_more
    </mat-icon>
  </mat-list-item>

  <mat-list-item *ngIf='menu.link!=false' [ngClass]="menu.isSub == true && menu.icono != ' ' ? 'ml-4':''"
                 (click)='clickMenu()'
                 [routerLink]='[menu.urlDestino]' title="{{menu.nombreContenido}}">
    <mat-icon matListIcon iconsmall>{{menu.icono}} </mat-icon>
    <h3 matLine *ngIf='!iconOnly' [ngClass]="{'color-selected': menu.click}" title="{{menu.nombreContenido}}">{{
      menu.nombreContenido }} </h3>
  </mat-list-item>
  <cdk-sidemenu-item *ngFor='let submenu of menu?.sub' [menu]='submenu' [allmenu]='allmenu' [iconOnly]='iconOnly'
                     [secondaryMenu]='true'></cdk-sidemenu-item>
</mat-nav-list>
