import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filtercode'
})
export class FilterPipe implements PipeTransform {

  transform(value: Array<any>, ...args: any): any {
    if (!value || value.filter(t => {
      return t[args[0]] === args[1];
    }).length === 0) {
      return [];
    }
    return value.filter(t => {
      return t[args[0]] === args[1];
    })[0][args[2]];
  }

}
