import {Component, Input} from '@angular/core';
import {ROLES} from '../../../environments/environment';
import {usuarioService} from '../coreservices/usuario.service';

@Component({
  selector: 'app-cdk-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent {
  @Input() notifications = [];


  constructor(private _usuarioService: usuarioService) {
  }

  asistenteClick() {
    const userRole = JSON.parse(localStorage.getItem('sessionLocal'))?.rolUsuario;
    const userCode = JSON.parse(localStorage.getItem('sessionLocal'))?.idPersona;
    if (userRole === ROLES.CIUDADANOMICAV && userCode) {
      this._usuarioService.addViewContent('45', userCode)
        .subscribe((result) => {
          console.log(result);
        });
    }
  }
}
