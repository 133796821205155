import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';


const routes: Routes = [
  {path: 'auth', loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)},
  {path: 'login', loadChildren: () => import('../login/login.module').then(m => m.LoginModule)},
  {path: 'registro', loadChildren: () => import('../persona/registro/registro.module').then(m => m.RegistroModule)},
  {
    path: 'restablecer',
    loadChildren: () => import('../persona/olvidocontrasena/olvidocontrasena.module').then(m => m.OlvidoContrasenaModule)
  },
  {path: '**', redirectTo: 'auth/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]

})
export class LazyLoadModule {
}
