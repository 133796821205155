import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {SpinnerService} from '../coreservices/SpinnerService.service';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private spinnerService: SpinnerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userData = JSON.parse(localStorage.getItem('sessionLocal'));
    const token: string = userData?.token;
    let request = req.clone();

    if (token) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }

    if (environment.apim) {
      request = request.clone({
        setHeaders: {
          [environment.apim.key]: environment.apim.value
        }
      });
    }

    this.spinnerService.requestStarted();
    return next.handle(request).pipe(
      // PROFILING
      finalize(() => {
        this.spinnerService.requestEnded();
      }),

      catchError((err: HttpErrorResponse) => {
        this.spinnerService.resetSpinner();

        if (err.status === 401) {
          this.toastr.error(err.error?.mensaje || 'Por favor inicie sesión para continuar', '', {timeOut: 8000});
          this.router.navigateByUrl('/login').then(() => {});
        } else if (err.status === 400) {
          if (err.error && err.error.mensaje) {
            this.toastr.error(err.error.mensaje, '', {timeOut: 8000});
          } else {
            this.toastr.error('Se presento un problema al realizar esta acción.', '', {timeOut: 8000});
          }
        } else if (err.status === 403) {
          this.toastr.error(err.error?.mensaje || 'Usted no está autorizado para realizar esta acción', '', {timeOut: 8000});
          this.router.navigateByUrl('/').then(() => {});
        } else if (err.status === 404) {
          this.toastr.error(err.error && err.error.mensaje ? err.error.mensaje : 'El recurso no fue encontrado.', '', {timeOut: 8000});
        } else if (err.status === 0 && err.statusText === 'Unknown Error' && err.name === 'HttpErrorResponse') {
          this.toastr.error('Revise su conexión a internet', '', {timeOut: 8000});
        } else {
          this.toastr.error(err.error ? err.error.mensaje : err.message, '', {timeOut: 8000});
        }

        return throwError(err);

      })
    );
  }
}
