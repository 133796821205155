import {Component, OnInit, Input} from '@angular/core';
import {ToolbarHelpers} from './toolbar.helpers';

@Component({
  selector: 'cdk-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() sidenav;
  @Input() sidebar;
  @Input() drawer;
  @Input() matDrawerShow;
  isLoggedIn = false;
  toolbarHelpers = ToolbarHelpers;

  constructor() {
  }

  ngOnInit() {
    this.isLoggedIn = !!JSON.parse(localStorage.getItem('sessionLocal'))?.codigoUsuario;

    const user = JSON.parse(localStorage.getItem('sessionLocal'));
    if (user) {
      this.toolbarHelpers.currentUser.currentEmail = user.emailUsuario;
      this.toolbarHelpers.currentUser.currentUserName = user.nombreUsuario;
    }
  }
}
