import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-confirm-modal-description',
  templateUrl: './confirm-modal-description.component.html',
  styleUrls: ['./confirm-modal-description.component.scss']
})
export class ConfirmModalDescriptionComponent implements OnInit {
  EliminarArchivo: FormGroup;

  constructor(public dialogRef: MatDialogRef<ConfirmModalDescriptionComponent>,
              private builder: FormBuilder,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.EliminarArchivo = this.builder.group({
      motivoEliminar: ['', Validators.required]

    });
  }

  ConfirmarEliminacion() {
    if ((!this.EliminarArchivo.valid && this.data.desistir) && !this.data?.isFormDisabled) {
      this.toastr.error('El motivo del desistimiento es obligatorio.', '', {timeOut: 8000});
      return;
    }
    if (!this.EliminarArchivo.valid && !this.data?.isFormDisabled) {
      this.toastr.error('El motivo de la eliminación es obligatorio.', '', {timeOut: 8000});
      return;
    }

    this.dialogRef.close(this.EliminarArchivo.value);

  }
}
