import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cdk-toolbar-notification',
  templateUrl: './toolbar-notification.component.html',
  styleUrls: ['./toolbar-notification.component.scss']
})
export class ToolbarNotificationComponent {
  cssPrefix = 'toolbar-notification';
  isOpen = false;
  @Input() notifications = [];

}
