import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'cdk-sidemenu-item',
  templateUrl: './sidemenu-item.component.html',
  styleUrls: ['./sidemenu-item.component.scss']
})
export class SidemenuItemComponent implements OnInit {

  @Input() menu: any;
  @Input() help = false;
  @Input() allmenu;
  @Input() iconOnly: boolean;
  @Input() secondaryMenu = false;

  constructor() {
  }

  ngOnInit() {
  }
  chechForChildMenu() {
    return !!(this.menu && this.menu.sub);
  }

  clickMenu() {
    this.allmenu.forEach(submenu => {
      this.changeStateOpen(submenu);
    });
    this.menu.click = !this.menu.click;
  }

  changeStateOpen(submenu) {
    submenu.sub?.forEach(obj => {
      obj.click = false;
      if (obj.sub?.length > 0) {
        this.changeStateOpen(obj);
      }
    });
    submenu.click = false;
  }

}
