import {Component, OnInit, Input, HostListener, ElementRef} from '@angular/core';
import {ROLES} from '../../../environments/environment';

@Component({
  selector: 'app-cdk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  isOpen = false;
  Hari;
  isLoggedIn = false;
  isCiudadano = false;

  @Input() currentUser = null;

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    const usuario = JSON.parse(localStorage.getItem('sessionLocal'));
    const userRole = usuario?.rolUsuario;
    this.isLoggedIn = !!usuario?.codigoUsuario;
    this.isCiudadano = userRole === ROLES.CIUDADANOMICAV;
  }

  cerrarSesion() {
    localStorage.clear();
    location.reload();
  }
}
