import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {LazyLoadModule} from './lazy-load/lazy-load.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {CustomHttpInterceptor} from './core/Interceptor/custom-http-interceptor';
import {MatInputModule} from '@angular/material/input';
import {SpinnerLoadComponent} from './core/LoadPages/spinner-load/spinner-load.component';
import {SpinnerService} from './core/coreservices/SpinnerService.service';
import {PhoneMaskDirective} from '../app/directive/phone-mask.directive';
import {NgxFlagPickerModule} from 'ngx-flag-picker';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {registerLocaleData} from '@angular/common';
import localeES from '@angular/common/locales/es';
import {ParametricasServices} from './AdministracionParametricasGenerales/Services/parametricas.services';

registerLocaleData(localeES, 'es');

@NgModule({
  declarations: [
    AppComponent,
    SpinnerLoadComponent,
    PhoneMaskDirective,

  ],
  imports: [
    BrowserModule,
    LazyLoadModule,
    CoreModule,
    BrowserAnimationsModule,
    MatInputModule,
    HttpClientModule,
    NgxFlagPickerModule,
    MatCheckboxModule,

  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }, ParametricasServices,
    SpinnerService,
    {provide: MAT_DATE_LOCALE, useValue: 'es-CO'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
