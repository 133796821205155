import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
  RequestResponseDocumento,
  RequestResponseDocumentoWithObject,
} from '../Models/DocumentoModel';
import {environment} from '../../../environments/environment';
import {EditarVideoDTO} from '../../AdministracionCentroAyuda/Models/EditarVideoModel';

@Injectable()
export class cargarDocumentoService {
  private baseUrl = environment.apiBaseUrl;
  usuarioLogueado = JSON.parse(localStorage.getItem('sessionLocal'));

  constructor(private http: HttpClient) {
  }

  getDocumentosByPersona(
    categoriaMenu: string,
    categoriaTab: string,
    idUsuario: string
  ): Observable<RequestResponseDocumento> {
    const formData = new FormData();
    formData.append('IdUsuario', idUsuario);
    formData.append('IdCategoriaModulo', categoriaTab);
    formData.append('IdCategoriaDocumento', categoriaMenu);

    return this.http
      .post<RequestResponseDocumento>(
        `${this.baseUrl}/MisDocumentos/ConsultarDocumentos`,
        formData
      )
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  getDocumentosCentroAyuda(
    categoriaMenu: string,
    categoriaTab: string,
    idUsuario: string
  ): Observable<RequestResponseDocumento> {
    const formData = new FormData();
    formData.append('IdUsuario', idUsuario);
    formData.append('IdModeloRelacionado', categoriaTab);
    formData.append('IdCategoriaDocumento', categoriaMenu);

    return this.http
      .post<RequestResponseDocumento>(
        `${this.baseUrl}/MisDocumentos/ConsultarDocumentos`,
        formData
      )
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  generarPdfDeTiff(url: string): Observable<any> {
    return this.http
      .post<any>(
        `${this.baseUrl}/MisDocumentos/GenerarPdfDeTiff`,
        {
          url
        }
      )
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  SubirDocumento(document: FormData): Observable<any> {
    const fd = new FormData();
    fd.append('IdTipoDocIdentidad', document['idTipoDocIdentidad']);
    fd.append(
      'IdUsuario',
      JSON.parse(localStorage.getItem('sessionLocal'))?.id
    );
    fd.append('NombreDocumento', document['numeroDocumentoIdentidad']?.trim());
    fd.append('IdCategoriaDocumento', '1');
    fd.append('Documento', document['documento']);
    fd.append('IdTipologia', document['IdTipologia']);
    fd.append('IdExtensionDocumento', document['IdExtensionDocumento']);
    fd.append('extensionFile', document['extensionFile']);

    return this.http
      .post<any>(
        `${this.baseUrl}/MisDocumentos/CrearDocumento`,
        fd
      )
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  SubirDocumentoActuacion(document: any): Observable<any> {
    const fd = new FormData();
    fd.append(
      'IdUsuario',
      document.IdUsuario
    );
    fd.append('NombreDocumento', document.NombreDocumento);
    fd.append('IdCategoriaDocumento', '1');
    fd.append('Documento', document.Documento);
    fd.append('IdTipologia', document.IdTipologia);
    fd.append('IdExtensionDocumento', document.IdExtensionDocumento);
    fd.append('idCodigoSim', document.idCodigoSim);
    fd.append('TypeRequest', document.TypeRequest);

    return this.http
      .post<any>(
        `${this.baseUrl}/MisDocumentos/CrearDocumento`,
        fd
      )
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  eliminarDocumento(
    idDocument: number,
    motivo: string
  ): Observable<RequestResponseDocumentoWithObject> {
    return this.http
      .post<RequestResponseDocumentoWithObject>(
        `${this.baseUrl}/MisDocumentos/Eliminar`,
        {
          id: idDocument,
          motivoEliminarDocumento: motivo,
        }
      )
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  getTipoDocumento(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/Parametrica/ConsultaTipologia`
    );
  }

  descargarPDF(url: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.set('Accept', '*/*');
    return this.http.get(url, {headers: headers, responseType: 'blob'});
  }

  SubirVideo(document: FormData, archivoSubido): Observable<any> {
    const formData = new FormData();
    formData.append('IdModeloRelacionado', document['idModeloRelacionado']);
    formData.append('IdCategoriaDocumento', document['idCategoriaDocumento']);
    formData.append('NombreDocumento', document['nombreDocumento']);
    formData.append('descripcionDocumento', document['descripcionDocumento']);
    formData.append('Url', document['url']);
    formData.append('Documento', archivoSubido);
    formData.append('IdTipologia', document['idTipologia']);
    formData.append('IdExtensionDocumento', document['idExtensionDocumento']);
    formData.append('IdUsuario', JSON.parse(localStorage.getItem('sessionLocal'))?.id ?? '0');

    return this.http
      .post<any>(`${this.baseUrl}/MisDocumentos/CrearCentroAyuda`, formData)
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  EditarVideo(document: EditarVideoDTO): Observable<any> {
    document.idUsuarioMts = this.usuarioLogueado.id;
    document.idUsuario = parseInt(this.usuarioLogueado.id, 10);
    return this.http
      .put<any>(`${this.baseUrl}/MisDocumentos/Editar`, document)
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  asociarDocumentoAnexo(payload) {
    return this.http
      .post<any>(
        `${this.baseUrl}/TramiteDocumento/AsociarTramiteDocumento`,
        payload
      )
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  obtenerExtensionesPermitidas(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/Parametrica/ConsultaExtensionesPermitidas`)
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  getTipologies(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/Parametrica/ConsultaTipologia`);
  }
}
