import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  mouseStop = null;
  time = 15 * 1000 * 60;
  timeInterval = 0;

  public constructor(private readonly toastr: ToastrService) {
    const fn = () => {
      if (((this.time / 1000) - this.timeInterval === 60)) {
        if (JSON.parse(localStorage.getItem('sessionLocal'))?.id !== undefined) {
          this.toastr.error(
            `Se presentó inactividad, la sesión se va cerrar en 1 minuto!!`,
            '',
            {timeOut: 8000}
          );
        }
      }
      this.timeInterval++;
    };
    setInterval(fn, 1000);

    document.querySelector('body').addEventListener('mousemove', (_) => {
      this.timeInterval = 0;
      clearTimeout(this.mouseStop);
      this.mouseStop = setTimeout(() => {
        this.mouseEvent();
      }, this.time);
    });
  }

  mouseEvent() {
    if (JSON.parse(localStorage.getItem('sessionLocal'))?.id !== undefined) {
      localStorage.clear();
      location.reload();
    }
  }
}
