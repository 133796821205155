import {Injectable} from '@angular/core';
import {HttpClient, HttpHandler, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CategoriaMenu} from '../coremodels/CategoriaMenuModel';

@Injectable()
export class CategoriaModuloService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  ObtenerModuloMenu(): Observable<CategoriaMenu> {
    return this.http.get<CategoriaMenu>(`${this.baseUrl}/CategoriaDocumentos/ObtenerActivos`);
  }


}
