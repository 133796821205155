import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Parametrica, RequestResponseParametricas, RequestResponseTipologia, Tipologia} from '../Models/parametricaModel';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ParametricasServices {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  getParametricas(): Observable<RequestResponseParametricas> {
    return this.http.get<RequestResponseParametricas>(`${this.baseUrl}/Parametrica/ObtenerParametrica`);
  }

  getObtenerOpcionValor(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/Parametrica/ObtenerOpcionValor`);
  }

  getTipoCampo(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/Parametrica/ObtenerTipoCampo`);
  }

  CrearParametrica(opcion: Parametrica): Observable<any> {
    return this.http.post(`${this.baseUrl}/Parametrica/CrearParametrica`, opcion);
  }

  editarParametrica(id: number, opcion: Parametrica): Observable<any> {
    return this.http.put(`${this.baseUrl}/Parametrica/EditarParametrica/` + id, opcion);
  }

  agregarOpcionValorPorCodigoIntegracion(peticion): Observable<any> {
    return this.http.post(`${this.baseUrl}/Parametrica/AgregarOpcionValor`, peticion);
  }

  EliminarParametrica(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/Parametrica/EliminarParametrica/` + id);
  }

  getParametricasById(id: number): Observable<RequestResponseParametricas> {
    return this.http.get<RequestResponseParametricas>(`${this.baseUrl}/Parametrica/ObtenerParametricaId/` + id);
  }

  public obtenerTipologias(): Observable<RequestResponseTipologia> {
    return this.http.get<RequestResponseTipologia>(`${this.baseUrl}/Tipologia/Consultar`)
      .pipe(catchError(err => {
        throw err;
      }));
  }

  public crearListaTipologia(tipologia: Tipologia[]): Observable<RequestResponseTipologia> {
    return this.http.post<RequestResponseTipologia>(`${this.baseUrl}/Tipologia/CrearLista`, tipologia)
      .pipe(catchError(err => {
        throw err;
      }));
  }

  public eliminarTipologia(id: number): Observable<RequestResponseTipologia> {
    return this.http.delete<RequestResponseTipologia>(`${this.baseUrl}/Tipologia/Eliminar/${id}`)
      .pipe(catchError(err => {
        throw err;
      }));
  }

  public obtenerParametricaPorCodigo(codigo: string): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/Parametrica/ObtenerPorCodigo`, {
        codigo
      })
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  generarExcelPost(data, path): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${this.baseUrl}/${path}`, data, { headers, responseType: 'blob' as 'json'});
  }

  obtenerParametricaPorUrl(path: string): Observable<RequestResponseTipologia> {
    return this.http.get<RequestResponseTipologia>(`${this.baseUrl}/${path}`)
      .pipe(catchError(err => {
        throw err;
      }));
  }

  
}
