<div class="toolbar-notification-container">
  <button mat-icon-button [routerLink]="['asistente-virtual']" title="Asistente Virtual" (click)="asistenteClick()">
    <em class="micav-icon-chat-grey"></em>
  </button>
</div>

<ng-template #thenBlock>
  <ng-scrollbar class="content">
    <div *ngFor="let notification of notifications; last as isLast">
      <div class="notification" fxLayout="row" fxLayoutAlign="start center" mat-ripple>
        <mat-icon class="icon">message</mat-icon>
        <div class="title" fxLayout="column">
          <div class="name">{{ notification.title }}</div>
          <div class="time">{{ notification.lastTime }}</div>
        </div>
        <span fxFlex></span>
      </div>
      <div class="divider" *ngIf="!isLast"></div>
    </div>
  </ng-scrollbar>
</ng-template>

<ng-template #elseBlock>
  <div class="no" fxLayout="row" fxLayoutAlign="center center">暂无通知</div>
</ng-template>







